<script>
import Banner from '@/components/general/Banner'
import ApplicationsTable from '../profile/ApplicationsTable'
export default {
  name: 'HomepageUser',
  components: { ApplicationsTable, Banner },
  computed: {
    username () {
      return (this.$store.getters.getUser && this.$store.getters.getUser.name) || ''
    }
  }
}
</script>
<template>
  <div class="homepage-user-applications--container">
    <banner
      background-color="secondary-lightest"
      background-figure="/assets/images/workspace-banners/lists.png"
      background-position="center right"
    >
      <div class="homepage-user-applications--banner__wrapper">
        <h5>{{ $t('home.user.applications.banner:title') }}</h5>
        <p class="body--1">{{ $t('home.user.applications.banner:subtitle') }}</p>
      </div>
    </banner>
    <applications-table/>
    <router-view/>
  </div>
</template>
<style lang="scss">
.homepage-user-applications--container {
  padding-bottom: 40px;
  .homepage-user-applications--banner__wrapper {
    text-align: left;
    padding: 50px 24px;
    > * {
      margin: 0;
    }
  }
}
</style>
