<script>
export default {
  name: 'ApplicationsTable',
  data () {
    return {
      selectedTab: 0
    }
  },
  watch: {
    selectedTab () {
      this.redirect(this.selectedTab)
    },
    '$route.name': {
      immediate: true,
      handler () {
        if (this.$route.name.includes('expired')) {
          this.selectedTab = 2
        } else if (this.$route.name.includes('analysis')) {
          this.selectedTab = 1
        } else if (this.$route.name.includes('pending')) {
          this.selectedTab = 0
        } else {
          this.redirect(0)
        }
      }
    }
  },
  methods: {
    redirect (index) {
      switch (index) {
        case 0:
          this.$router.push({ name: this.selectedWorkspace.type + '.home.user.applications.pending' })
          break
        case 1:
          this.$router.push({ name: this.selectedWorkspace.type + '.home.user.applications.under.analysis' })
          break
        case 2:
          this.$router.push({ name: this.selectedWorkspace.type + '.home.user.applications.expired' })
          break
      }
    }
  }
}
</script>
<template>
  <div class="applications-table--container">
    <v-tabs v-if="userCanManage(selectedWorkspace.id)" :show-arrows="isMobile" v-model="selectedTab" slider-size="4">
      <v-tab class="btn">{{ $t('applications.menu.pending.list') }}</v-tab>
      <v-tab class="btn">{{ $t('applications.menu.under.analysis.list') }}</v-tab>
      <v-tab class="btn">{{ $t('applications.menu.expired.list') }}</v-tab>
    </v-tabs>
  </div>
</template>
<style lang="scss">
.applications-table--container {
  .theme--light.v-tabs > .v-tabs-bar {
    color: $primary-medium !important;
    caret-color: $primary-medium !important;
    background-color: transparent;
    margin-bottom: 20px;
    z-index: 10;
  }
  .v-tabs {
    margin: 20px 0 0;
  }
}
</style>
